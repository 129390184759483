import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { addListener, launch } from 'devtools-detector';
import './common/theme.scss'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import Clipboard from 'v-clipboard'


const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus, { locale: zhCn })
app.use(Clipboard)
// 修复 el-table，报错 ResizeObserver loop limit exceeded 问题
const debounce = (fn, delay) => {
    let timer = null

    return function () {
        let context = this
        let args = arguments
        clearTimeout(timer)

        timer = setTimeout(function () {
            fn.apply(context, args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16)
        super(callback)
    }
}

// IconPark（字节图标库）
import { install } from '@icon-park/vue-next/es/all';
install(app)

// 全局样式（自定义）
import './common/style.scss'

// 全局方法（自定义）
import Plug from './common/plug.js'
app.use(Plug)

// 全局组件（自定义）
import ContainerBottom from './components/ContainerBottom.vue'
app.component('ContainerBottom', ContainerBottom)

// 挂载
app.mount('#app')
